/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
import {
  booleanFilters,
  COLUMNS_FIELDS_DEFAULT,
  dateFilters,
  filtersWithOptions,
  keepNullFields,
  objectFilters,
  showCompletedFilters,
  TASKS_FIELDS,
} from 'pages/tasks/constants';
import { IdName, SortOrderOption } from 'types/common-types';
import { GetMainMenuItemsArgs } from 'pages/tasks/types/function-types';
import { ShowCompletedPeriodEnum } from 'services/production-workflow.model';
import { ColGroupDef, ColDef, GridReadyEvent, ColumnState } from 'ag-grid-community';
import { ColumnOrderItemT, ColumnSizesT, TasksFiltersEnum, TasksFiltersT } from 'pages/tasks/types/types';

const extractSelectedOptions = <T>(field: { selectedOptions: T[] }): T[] | undefined => {
  return field.selectedOptions.length > 0 ? field.selectedOptions : undefined;
};

const extractBooleanSelectedOptions = (field: { selectedOptions: string[] }) => {
  const { selectedOptions } = field;
  if (!selectedOptions.length) {
    return null;
  }

  const allTrue = selectedOptions.every((option) => option === 'true');
  const allFalse = selectedOptions.every((option) => option === 'false');

  if (allTrue) return true;

  if (allFalse) return false;

  return null;
};

const extractMatchingOptions = (field: { selectedOptions: string[]; options: IdName[] }) => {
  return field.options.filter((option) => field.selectedOptions.includes(option.id));
};

const cleanObject = (obj: Record<string, any>) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      (value !== null || keepNullFields.includes(key as TasksFiltersEnum)) &&
      value !== undefined &&
      value !== '' &&
      value !== 0 &&
      !(Array.isArray(value) && (value.length === 0 || value.every((v) => v === null)))
    ) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const generateRequestBody = (
  filters: TasksFiltersT,
  columns: ColumnOrderItemT[] | undefined | null,
  columnSizes: ColumnSizesT,
) => {
  const showCompletedPeriod = Number((filters.filters[TasksFiltersEnum.ShowCompleted]?.value as IdName)?.id);

  return {
    // This is to remove the column indexes for bulk assignment and action menu
    columns: columns ?? COLUMNS_FIELDS_DEFAULT,

    filters: cleanObject({
      // Product
      [TasksFiltersEnum.ProductVendor]: extractMatchingOptions(filters.filters[TasksFiltersEnum.ProductVendor]),

      // Production
      [TasksFiltersEnum.ProductionStatus]: extractSelectedOptions(filters.filters[TasksFiltersEnum.ProductionStatus]),
      [TasksFiltersEnum.ProductionDeadline]: filters.filters[TasksFiltersEnum.ProductionDeadline].value,
      [TasksFiltersEnum.RootProductionDeadline]: filters.filters[TasksFiltersEnum.RootProductionDeadline].value,

      // Task
      [TasksFiltersEnum.IsInQueue]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.IsInQueue]),
      [TasksFiltersEnum.IsFailed]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.IsFailed]),
      [TasksFiltersEnum.FailedAt]: filters.filters[TasksFiltersEnum.FailedAt].value,
      [TasksFiltersEnum.TaskType]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskType]),
      [TasksFiltersEnum.TaskStatus]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskStatus]),
      [TasksFiltersEnum.TaskPriority]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskPriority]),
      [TasksFiltersEnum.ReportingPeriod]: filters.filters[TasksFiltersEnum.ReportingPeriod].value,
      [TasksFiltersEnum.ReasonForFailure]: extractSelectedOptions(filters.filters[TasksFiltersEnum.ReasonForFailure]),

      // Assignment
      [TasksFiltersEnum.Assignee]: extractMatchingOptions(filters.filters[TasksFiltersEnum.Assignee]),
      [TasksFiltersEnum.AssigneeType]: extractSelectedOptions(filters.filters[TasksFiltersEnum.AssigneeType]),

      // Warnings
      [TasksFiltersEnum.AssigneeRequired]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.AssigneeRequired]),
      [TasksFiltersEnum.TimeLimitExceeded]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.TimeLimitExceeded]),

      // Time
      [TasksFiltersEnum.DueDate]: filters.filters[TasksFiltersEnum.DueDate].value,

      // Show Completed
      [TasksFiltersEnum.ShowCompleted]: {
        type: filters.filters[TasksFiltersEnum.ShowCompleted].radioValue,
        period:
          filters.filters[TasksFiltersEnum.ShowCompleted].radioValue === ShowCompletedPeriodEnum.Some
            ? showCompletedPeriod
            : undefined,
      },
    }),
    queries: cleanObject({
      // Product
      [TasksFiltersEnum.ProductName]: filters.queries[TasksFiltersEnum.ProductName],
      [TasksFiltersEnum.ProductVersion]: Number(filters.queries[TasksFiltersEnum.ProductVersion]),
      [TasksFiltersEnum.RootProductName]: filters.queries[TasksFiltersEnum.RootProductName],
      [TasksFiltersEnum.RootProductVersion]: Number(filters.queries[TasksFiltersEnum.RootProductVersion]),
      [TasksFiltersEnum.RootProductVariant]: filters.queries[TasksFiltersEnum.RootProductVariant],
      [TasksFiltersEnum.RootProductConfiguration]: filters.queries[TasksFiltersEnum.RootProductConfiguration],

      // Production
      [TasksFiltersEnum.ProductionKey]: filters.queries[TasksFiltersEnum.ProductionKey],
      [TasksFiltersEnum.ProductVariant]: filters.queries[TasksFiltersEnum.ProductVariant],
      [TasksFiltersEnum.RootProductionKey]: filters.queries[TasksFiltersEnum.RootProductionKey],
      [TasksFiltersEnum.ProductConfiguration]: filters.queries[TasksFiltersEnum.ProductConfiguration],

      // Order
      [TasksFiltersEnum.Client]: filters.queries[TasksFiltersEnum.Client],
      [TasksFiltersEnum.OrderKey]: filters.queries[TasksFiltersEnum.OrderKey],
      [TasksFiltersEnum.PrimaryClient]: filters.queries[TasksFiltersEnum.PrimaryClient],
      [TasksFiltersEnum.ExternalOrderNumber]: filters.queries[TasksFiltersEnum.ExternalOrderNumber],
      [TasksFiltersEnum.MarketPlaceOrderNumber]: filters.queries[TasksFiltersEnum.MarketPlaceOrderNumber],

      // Task
      [TasksFiltersEnum.TaskKey]: filters.queries[TasksFiltersEnum.TaskKey],
      [TasksFiltersEnum.TaskName]: filters.queries[TasksFiltersEnum.TaskName],
      [TasksFiltersEnum.TaskDescription]: filters.queries[TasksFiltersEnum.TaskDescription],
      [TasksFiltersEnum.ReasonForFailure]: filters.queries[TasksFiltersEnum.ReasonForFailure],

      // Assignment
      [TasksFiltersEnum.AssigneePosition]: filters.queries[TasksFiltersEnum.AssigneePosition],
      [TasksFiltersEnum.AssigneeDepartment]: filters.queries[TasksFiltersEnum.AssigneeDepartment],
    }),
    pins: {
      left: filters.pins.left as TasksFiltersEnum[],
      right: filters.pins.right as TasksFiltersEnum[],
    },
    sort: {
      orderBy: filters.sort.orderBy,
      orderOption: filters.sort.orderOption || SortOrderOption.Ascending,
    },
    sizes: columnSizes ?? {},
  };
};

export const transformToAgGridColumnState = (columns: ColGroupDef[]): ColumnState[] => {
  return columns.flatMap((group) =>
    group.children.map((column: ColDef) => ({
      colId: column.colId!,
      hide: column.hide,
      pinned: column.pinned,
      width: column.width ?? 200,
      sort: column.sort,
    })),
  );
};

export const sortSavedColumnsState = (columns: ColumnState[], columnOrder: ColumnOrderItemT[] | undefined): ColumnState[] => {
  if (!columnOrder) {
    return columns;
  }
  const columnMap = new Map(columns.map((col) => [col.colId, col]));

  const getAllSelectColumn = columns.find((column) => column.colId === 'all_select');
  const getMoreActionsColumn = columns.find((column) => column.colId === 'more_actions_menu');

  const sortedColumnsByColumnOrder = columnOrder
    .map((column) => columnMap.get(column.colId))
    .filter((column): column is ColumnState => column !== undefined);

  const sortedColumns: ColumnState[] = [
    ...(getAllSelectColumn ? [getAllSelectColumn] : []),
    ...sortedColumnsByColumnOrder,
    ...(getMoreActionsColumn ? [getMoreActionsColumn] : []),
  ];

  return sortedColumns;
};

export const filterValidFields = (columnOrderArray: ColumnOrderItemT[]): ColumnOrderItemT[] => {
  return columnOrderArray?.filter((field) => field.colId !== 'all_select' && field.colId !== 'more_actions_menu');
};

export const getPinnedSide = (column: ColDef, pins: TasksFiltersT['pins'] | undefined): 'left' | 'right' | null => {
  if (column.colId === 'all_select') {
    return 'left';
  }

  if (pins?.left?.includes(column.field as TasksFiltersEnum)) {
    return 'left';
  } else if (pins?.right?.includes(column.field as TasksFiltersEnum)) {
    return 'right';
  }

  return null;
};

export const getFormattedFilters = (filtersArray, filters) => {
  const result = JSON.parse(JSON.stringify(filters));
  dateFilters.forEach((key) => {
    if (filtersArray && filtersArray[key]) {
      result[key] = { value: filtersArray[key] };
    }
  });

  objectFilters.forEach((key) => {
    if (filtersArray && filtersArray[key]) {
      result[key] = {
        ...result[key],
        options: filtersArray[key],
        selectedOptions: filtersArray[key].map((assignee) => assignee.id),
      };
    }
  });

  filtersWithOptions.forEach((key) => {
    if (filtersArray && filtersArray[key]) {
      result[key] = {
        ...result[key],
        selectedOptions: filtersArray[key],
      };
    }
  });

  booleanFilters.forEach((key) => {
    if (filtersArray) {
      result[key] = {
        ...result[key],
        selectedOptions: filtersArray[key] || filtersArray[key] === false ? [String(filtersArray[key])] : [],
      };
    }
  });

  showCompletedFilters.forEach((key) => {
    if (filtersArray && filtersArray[key]) {
      const showCompleted = filtersArray[key];
      const { options } = filters[key];

      result[key] = {
        ...result[key],
        radioValue: showCompleted.type,
        value: options.find(({ id }) => id === String(showCompleted.period)) || options[0],
      };
    }
  });

  return result;
};

export const isTableEndReached = (api: GridReadyEvent['api']) => {
  const verticalScrollPosition = api.getVerticalPixelRange();
  const lastRowIndex = api.getDisplayedRowCount();
  const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex - 1);

  if (!lastRowNode || lastRowNode.rowTop == null) return false;

  const lastRowTopPixel = lastRowNode.rowTop;
  const viewportBottom = verticalScrollPosition.bottom;

  return viewportBottom >= lastRowTopPixel - 10;
};

export const checkHiddenColumn = (columnName: TasksFiltersEnum, activeColumns: ColumnOrderItemT[] | undefined): boolean => {
  const column = activeColumns?.find((col) => col.colId === columnName);
  return column ? column.hide : false;
};

export const getColumnWidth = (
  columnName: TasksFiltersEnum,
  sizes: { [key in TasksFiltersEnum]: number } | undefined,
): number | undefined => {
  const isColumnValid = TASKS_FIELDS.includes(columnName);

  let result: number | undefined;

  if (isColumnValid && sizes) {
    result = sizes[columnName] || undefined;
  } else if (!isColumnValid) {
    result = 80;
  } else {
    result = undefined;
  }

  return result;
};

export const getActiveFilters = (obj: Record<string, unknown>) => {
  const keys: string[] = [];

  if (obj.filters) {
    keys.push(...Object.keys(obj.filters));
  }

  if (obj.queries) {
    keys.push(...Object.keys(obj.queries));
  }

  return keys;
};

export const getMainMenuItems = ({ params, actions }: GetMainMenuItemsArgs) => {
  const { defaultItems } = params;
  const { resetColumns } = actions;

  const customResetColumnItem = {
    name: 'Reset Columns',
    action: resetColumns,
  };

  const filteredItems = defaultItems.filter((item) => item !== 'columnFilter' && item !== 'resetColumns');

  return [...filteredItems, customResetColumnItem];
};
