import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ArrowLongLeftIcon } from 'icons/arrow-long-left';
import { getValidDateValues } from 'pages/production/controllers/helpers';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { MultiInputDateRangeField } from '@mui/x-date-pickers-pro/MultiInputDateRangeField';
import { IconButton, InputAdornment } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { CheckIcon } from 'icons/check';
import s from './custom-calendar-layout.module.scss';

type OwnProps = {
  value?: DateRange<Dayjs>;
  withPredefinedOptions?: boolean;
  onChange: (dateRange: DateRange<Dayjs>) => void;
};

const separator = () => <ArrowLongLeftIcon className={s.separator_icon} />;

const predefinedOptions: { label: string; period: DateRange<Dayjs> }[] = [
  { label: 'Current reporting period', period: [dayjs().startOf('month'), dayjs().endOf('month')] },
  {
    label: 'Previous reporting period',
    period: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  },
  { label: '2 months ago', period: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')] },
  { label: '3 months ago', period: [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(3, 'month').endOf('month')] },
];

const CustomCalendarLayout: FC<OwnProps> = ({ value, withPredefinedOptions = false, onChange }) => {
  const validValue = useMemo(() => getValidDateValues(value), [value]);

  const [activeOption, setActiveOption] = useState<string | null>(null);

  const handleOnChange = useCallback(
    (val: DateRange<Dayjs>) => {
      const newValue = getValidDateValues(val);
      const isSameSelection = validValue.every((v, index) => v?.isSame(newValue[index], 'day'));

      if (!isSameSelection) {
        setActiveOption(null);
        onChange(newValue);
      }
    },
    [onChange, validValue],
  );

  const handleClear = useCallback(
    (index: 0 | 1) => {
      const newValue = [...validValue] as DateRange<Dayjs>;
      newValue[index] = null;
      handleOnChange(newValue);
    },
    [handleOnChange, validValue],
  );

  const handlePredefinedOptionClick = useCallback(
    (label: string, newValue: DateRange<Dayjs>) => {
      const isSameSelection = validValue.every((v, index) => v?.isSame(newValue[index], 'day'));

      if (!isSameSelection) {
        setActiveOption(label);
        onChange(newValue);
      }
    },
    [onChange, validValue],
  );

  useEffect(() => {
    const matchedOption = predefinedOptions.find(({ period }) => validValue.every((v, index) => v?.isSame(period[index], 'day')));

    setActiveOption(matchedOption ? matchedOption.label : null);
  }, [validValue]);

  return (
    <div className={s.container}>
      <div className={s.multi_input_container}>
        <MultiInputDateRangeField
          value={validValue}
          format="DD.MM.YYYY"
          slots={{ separator }}
          classes={{ root: s.multi_input }}
          onChange={handleOnChange}
          slotProps={{
            textField: ({ position }) => ({
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear(position === 'start' ? 0 : 1)} size="small">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
                placeholder: 'DD.MM.YYYY',
              },
            }),
          }}
        />
      </div>
      <DateRangeCalendar
        reduceAnimations
        value={validValue}
        className={s.date_picker}
        onChange={handleOnChange}
        classes={{ monthContainer: s.date_header }}
        slotProps={{
          nextIconButton: { className: s.date_button },
          previousIconButton: { className: s.date_button },
        }}
      />
      {withPredefinedOptions && (
        <div className={s.predefined_options}>
          {predefinedOptions.map(({ label, period }) => {
            const isActive = activeOption === label;

            return (
              <button
                key={label}
                className={s.predefined_button}
                data-isActive={isActive}
                type="button"
                onClick={() => handlePredefinedOptionClick(label, period)}
              >
                <div>{label}</div>
                {isActive && <CheckIcon width={16} height={16} stroke="#437BFF" />}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomCalendarLayout;
