import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { AppState } from 'redux/store';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TasksFiltersEnum } from 'pages/tasks/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import CustomCalendarLayout from 'components/custom-calendar-layout/custom-calendar-layout';

type Props = {
  fieldName: TasksFiltersEnum;
};

const RangeSelector: FC<Props> = ({ fieldName }: Props) => {
  const dispatch = useDispatch();
  const value = useSelector((state: AppState) => state.tasks.filters.filters[fieldName]?.value) as DateRange<Dayjs>;

  return (
    <CustomCalendarLayout
      value={value}
      withPredefinedOptions={fieldName === TasksFiltersEnum.ReportingPeriod}
      onChange={(v) => dispatch(TasksActions.onFilterChange(fieldName, v))}
    />
  );
};

export default RangeSelector;
